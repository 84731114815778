import React from "react"
import { useScrollAnim } from "src/components/hooks/hooks"

import { Link } from "components/anti/link/link"

import appStore from "assets/img/btn-apple.svg"
import playStore from "assets/img/btn-google.svg"
import comingSoon from "assets/img/img-btn-comingsoon.svg"

import plus from "assets/img/bpjs/logo-partner-plus.png"

export const BpjsOverview = ({ data }) => {
  const [trigger, anim] = useScrollAnim()

  return (
    <section className="py-main sc-bpjs-overview" ref={trigger}>
      <div className="container">
        <div className="row row-5">
          <div className="col-md-6 col-lg-4">
            <div className="d-flex justify-content-around align-items-center mb-5">
              <img
                src={data.logo1.sourceUrl}
                alt="logo"
                className={`${anim(1)} img-fluid img-content mx-3 mx-sm-down-0`}
              />
              <img
                src={plus}
                alt="logo"
                className={`${anim(2)} img-fluid img-plus mx-3 mx-sm-down-0`}
              />
              <img
                src={data.logo2.sourceUrl}
                alt="logo"
                className={`${anim(3)} img-fluid img-content mx-3 mx-sm-down-0`}
              />
            </div>
          </div>
          <div className="col-md-6 col-lg-5">
            <h2 className={`${anim(4)} font-weight-normal h1`}>
              {data.titleline1} +{" "}
              <span className="title2 font-weight-bold">{data.titleline2}</span>
            </h2>
            <div
              dangerouslySetInnerHTML={{ __html: data?.text }}
              className={`${anim(5)} mb-5`}
            />
            <div className="btn-group d-flex mt-3">
              <Link to="https://apps.apple.com/us/app/dompet-aman/id1588720217">
                <img
                  src={appStore}
                  className="img-fluid mr-2"
                  alt="App Store"
                />
              </Link>
              <Link to="https://play.google.com/store/apps/details?id=com.dompetaman">
                <img
                  src={playStore}
                  className="img-fluid ml-2"
                  alt="Play Store"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

import React from "react"

import { Slider } from "components/anti/slider/slider"

// import icon from "assets/img/bpjs/ic_fktp.svg"

export const BpjsListFasilitas = ({ data }) => {
    return (
        <section className="py-main sc-bpjs-fasilitasList">
            <div className="container">
                <Slider
                    visibleInitial={true}
                    visibleXxlDown={true}
                    visibleXlDown={true}
                    visibleLgDown={true}
                    visibleMdDown={true}
                    visibleSmDown={true}
                    showInitial={1.5}
                    showXxlDown={1.5}
                    showXlDown={1.5}
                    showLgDown={1}
                    showMdDown={1}
                    showSmDown={1}
                    arrowsInitial={true}
                    arrowsXxlDown={true}
                    arrowsXlDown={true}
                    arrowsLgDown={true}
                    arrowsMdDown={true}
                    arrowsSmDown={true}
                    className="d-md-block d-none"
                >
                    {data.map((fasil, i) => {
                        return (
                            <div className="fasilitas-list shadow py-5" key={i}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-2">
                                            <img src={fasil?.icon?.sourceUrl} alt="icon" className="img-fluid" />
                                        </div>
                                        <div className="col-10">
                                            <h2>{fasil.title}</h2>
                                            <p className="font-weight-bold">{fasil.label}</p>
                                            <ul className="list-unstyled list">
                                                {fasil.list.map((li, i) => (
                                                    <li key={i} className="pb-2">
                                                        {li.text}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )
                    })}
                </Slider>
                <div className="d-block d-md-none">
                    <div className="row">
                        {data.map((fasil, i) => {
                            return (
                                <div className="col-12 mb-4">
                                    <div className="fasilitas-list shadow py-5" key={i}>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-12 d-flex align-items-center mb-4">
                                                    <img src={fasil?.icon?.sourceUrl} alt="icon" className="img-fluid" />
                                                    <div className="ml-3">
                                                        <h2>{fasil.title}</h2>
                                                        <p className="font-weight-bold">{fasil.label}</p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <ul className="list-unstyled list">
                                                        {fasil.list.map((li, i) => (
                                                            <li key={i} className="pb-2">
                                                                {li.text}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </section>
    )
}


// export const dataFasil = [
//     {
//         title: "FKTP",
//         text: "Fasilitas Kesehatan Tingkat Pertama",
//         list: [
//             "Konsultasi dokter",
//             "Pelayanan obat, alat kesehatan, dan bahan medis sekali pakai",
//             "Pemeriksaan penunjang seperti laboratorium dan radiologi",
//             "Kamar rawat inap sesuai dengan indikasi medis",

//         ],
//     },
//     {
//         title: "FKRTL",
//         text: "Fasilitas Kesehatan Rujukan Tingkat Lanjutan",
//         list: [
//             "Mencakup semua fasilitasi di FKTP",
//             "Tindakan medis spesialistik (bedah maupun non bedah)",
//             "Alat kesehatan yang digunakan dalam rangka penyembuhan, termasuk alat bantu kesehatan",
//             "Rehabilitasi medis",
//             "Pelayanan darah",
//             "Pelayanan keluarga berencana",
//             "Memperoleh pelayanan ambulans darat atau air",
//         ],
//     },
// ]
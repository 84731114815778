import React from "react"
import { useScrollAnim } from "src/components/hooks/hooks"

import { Cover } from "../../anti/cover/cover"
import { Button } from "components/anti/buttons/buttons"

export const BpjsCover = ({ data }) => {
  const [trigger, anim] = useScrollAnim()
  return (
    <div ref={trigger} className="cover-full">
      <Cover
        variant="basic"
        theme="dark"
        img={data.image.sourceUrl}
        imgRatio="r-16-9"
        // label={data.label}
        title={data.title}
        // imgHeight={`h-vh-100`}
        className={`${anim(1)} sc-bpjs-cover animated fadeInUp`}
        labelClassName={`${anim(2)} font-weight-bold`}
        contentMaxWidth="content-wrapper mt-md-5"
        titleClassName={`${anim(3)} w-md-600px font-weight-normal`}
      >
        <div
          dangerouslySetInnerHTML={{ __html: data?.text }}
          className={`${anim(4)} w-md-400px mb-4`}
        />
        <div className="w-100 w-md-650px d-none d-md-inline-block">
          {data.buttons.map((button, i) => {
            const variant = i % 2 === 0 ? "primary" : "transparent"
            return (
              <div className="float-left mr-3" key={i}>
                <Button
                  variant={variant}
                  link={button?.button?.url}
                  key={i}
                  className={`w-sm-down-100 mt-2 ${anim(i + 1)}`}
                >
                  {button?.button?.text}
                </Button>
              </div>
            )
          })}
        </div>
        <div className="row row-2 d-flex d-md-none">
          {data.buttons.map((button, i) => {
            const variant = i % 2 === 0 ? "primary" : "transparent"
            return (
              <div className="col-12 col-md-5" key={i}>
                <Button
                  variant={variant}
                  link={button?.button?.url}
                  key={i}
                  className={`w-sm-down-100 mt-2 ${anim(i + 1)}`}
                >
                  {button?.button?.text}
                </Button>
              </div>
            )
          })}
        </div>
      </Cover>

      {/* <div className="text-cover text-white pb-5">
        <div className="container">
          <p>
            BPJS Kesehatan Indonesia merupakan layanan kesehatan publik yang
            berlaku sebagai asuransi kesehatan dasar masyarakat.
          </p>
          <p>
            Dompet Aman bekerjasama dengan BPJS untuk memudahkan akses data dan
            pembayaran tagihan BPJS kamu.
          </p>
        </div>
      </div> */}
    </div>
  )
}

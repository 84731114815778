import React from "react"
import { useScrollAnim } from "src/components/hooks/hooks"

import badge from "assets/img/orange-badge.svg"
import bpjsGfx from "assets/img/bpjs/bpjs-gfx.svg"

export const BpjsBenefits = ({ data }) => {
  const [trigger, anim] = useScrollAnim()

  return (
    <section className="py-main sc-bpjs-benefits sc-services-list" ref={trigger}>
      <div className="container">
        {/* <h2 className={`${anim(1)} caption`}>{data.label}</h2> */}
        <h2 className={`${anim(1)} mb-5 font-weight-normal`}>Keuntungan <span className="title2">BPJS Kesehatan</span> di Dompet Aman</h2>

        <div className="row">
          {data.list.map((data, i) => (
            <div key={i} className={`col-md-6 mb-5 ${anim(2 + i)}`}>
              <div className="d-flex align-items-start">
                <img
                  src={badge}
                  className="img-fluid"
                  alt={"logo"}
                />
                <div className="pl-3 content">
                  <h5>{data.title}</h5>
                  <p>{data.text}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <img src={bpjsGfx} alt="gfx" className="img-fluid bpjs-gfx" />
    </section>
  )
}

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Seo from "components/seo"
import Layout from "components/layout"
import { BpjsCover } from "components/pages/bpjs/cover"
import { BpjsOverview } from "components/pages/bpjs/overview"
import { BpjsBenefits } from "components/pages/bpjs/benefits"
import { Features } from "components/global/features/features"
import { BpjsFasilitas } from "components/pages/bpjs/fasilitas"
import { BpjsListFasilitas } from "components/pages/bpjs/listFasilitas"
import { BPJSKategori } from "components/pages/bpjs/kategori"
import { NavTabs, TabContent, TabPane } from "components/anti/tabs/tabs"
import { BpjsProsedur } from "components/pages/bpjs/prosedur"

const Bpjs = () => {
  const data = useStaticQuery(GET_BPJS_DETAILS)
  const blocks = data.wordPress.page.bpjsPage


  return (
    <Layout>
      <Seo title="BPJS Kesehatan" />
      <BpjsCover data={blocks.cover} />
      <div className="bpjs-nav container">
        <NavTabs
          data={[
            { name: "FASILITAS", target: "fasilitas" },
            { name: "PROSEDUR KLAIM", target: "prosedur-klaim" },
          ]}
        />
      </div>
      <TabPane id="fasilitas" className="active">
        <BpjsOverview data={blocks.overview} />
        <BpjsFasilitas data={blocks.facility} />
        <BpjsListFasilitas data={blocks.facility.list} />
        <BpjsBenefits data={blocks.benefits} />
        <BPJSKategori data={blocks.category} />
        <Features data={blocks.features} />
      </TabPane>
      <TabPane id="prosedur-klaim">
        <BpjsProsedur />
      </TabPane>
    </Layout>
  )
}

export default Bpjs

const GET_BPJS_DETAILS = graphql`
query BpjsDetail {
  wordPress {
    page(id: "bpjs", idType: URI) {
      bpjsPage {
        benefits {
          label
          list {
            text
            title
          }
        }
        cover {
          text
          title
          image {
            sourceUrl
          }
          buttons {
            button {
              text
              url
            }
          }
        }
        features {
          label
          features {
            downloadButton
            image {
              sourceUrl
            }
            buttons {
              text
              url
            }
            text
          }
        }
        overview {
          text
          titleline1
          titleline2
          logo1 {
            sourceUrl
          }
          logo2 {
            sourceUrl
          }
        }
        category {
          title
          text
          label
          list {
            title
            price
            facility
            capacity
          }
        }
        facility {
          title
          text
          list {
            title
            list {
              text
            }
            label
            icon {
              sourceUrl
            }
          }
          image {
            sourceUrl
          }
        }
      }
    }
  }
}

`

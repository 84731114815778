import React from "react"
import { useScrollAnim } from "src/components/hooks/hooks"

export const BpjsProsedur = () => {
    const [trigger, anim] = useScrollAnim()

    return (
        <section className="py-main sc-bpjs-prosedur" ref={trigger}>
            <div className="container mw-lg">
                <h2 className={`h1 font-weight-normal text-center mb-5 ${anim(1)}`}>Prosedur Klaim dari BPJS Kesehatan</h2>
                <ul className="prosedur-list">
                    {listProsedur.map((list, i) => {
                        return (
                            <li className={`prosedur-item d-flex align-items-center ${anim(1 + i)}`} key={i}>
                                <div className="circle"><h2 className="mb-0">0{i + 1}</h2></div>
                                <p className="mb-0">{list}</p>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </section>
    )
}

export const listProsedur = [
    `Pelayanan Kesehatan bagi Peserta dilaksanakan secara berjenjang sesuai kebutuhan medis dan kompetensi Fasilitas Kesehatan. Peserta harus memulai 
    dari FKTP (Fasilitas Kesehatan Tingkat Pertama) peserta terdaftar, kecuali dalam keadaan kegawatdaruratan medis.`,
    `Peserta datang ke FKTP (Fasilitas Kesehatan Tingkat Pertama) tempat peserta terdaftar dengan menunjukan nomor identitas peserta jaminan kesehatan dan/atau identitas lain yang diperlukan (KTP, SIM, KK).`,
    `Peserta memperoleh pelayanan kesehatan dari FKTP (Fasilitas Kesehatan Tingkat Pertama).`,
    `Setelah mendapatkan pelayanan peserta menandatangani bukti pelayanan pada lembar bukti pelayanan yang disediakan.`,
    `Apabila hasil pemeriksaan dokter ternyata peserta memerlukan pemeriksaan ataupun tindakan spesialis /sub spesialis, FKTP (Fasilitas Kesehatan Tingkat Pertama) akan memberikan surat rujukan ke fasilitas lanjutan yang bekerja sama dengan BPJS Kesehatan.`,
    `Apabila atas indikasi medis peserta masih memerlukan perawatan lanjutan di Rumah Sakit,  maka dokter FKRTL (Fasilitas Kesehatan Rujukan Tingkat Lanjutan) akan memberikan surat kontrol ulang yang digunakan sebagai  pengganti surat rujukan pada kunjungan berikutnya.`,
    `Apabila perawatan selanjutnya dapat ditangani di FKTP (Fasilitas Kesehatan Tingkat Pertama), maka dokter FKRTL (Fasilitas Kesehatan Rujukan Tingkat Lanjutan) akan memberikan SRB (Surat Rujuk Balik) yang ditujukan ke dokter FKTP tempat peserta terdaftar.`,
    `Peserta mendapatkan fasilitas rawat inap apabila diberikan rujukan dari FKTP (Fasilitas Kesehatan Tingkat Pertama)/FKRTL (Fasilitas Kesehatan Rujukan Tingkat Lanjutan) lain atau surat perintah rawat inap oleh Dokter Poli  Rawat Jalan, kecuali kasus Gawat Darurat.`,
    `Peserta mendapatkan perawatan pada kelas rawat sesuai haknya. Dalam hal kelas perawatan penuh, peserta dapat dirawat di kelas perawatan 1 (satu) tingkat lebih tinggi  dan paling lama 3 (tiga) hari sampai ruang sesuai hak kelasnya tersedia. Namun, jika  kelas rawat yang sesuai hak serta kelas rawat 1 tingkat lebih tinggi juga penuh, peserta dapat dirawat di kelas perawatan 1 (satu) tingkat lebih 
    rendah dan paling lama 3 (tiga)  hari sampai ruang sesuai hak kelasnya tersedia.`,
]
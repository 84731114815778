import React from "react"

import { Image } from "components/anti/image/image"

// import fasilImage from "assets/img/bpjs/fasilitas.jpg"
import floating3 from "assets/img/floating-1.svg"


export const BpjsFasilitas = ({ data }) => {
    return (
        <section className="sc-bpjs-fasilitas">
            <div className="row">
                <div className="col-md-6 order-md-last">
                    <Image src={data?.image?.sourceUrl} alt="image" ratio="r-4-3" className="img-fluid fasilitas-image" />
                </div>
                <div className="col-md-6 col-text order-md-first">
                    <div className="content text-white">
                        <h2 className="h1">{data.title}</h2>
                        <p>{data.text}</p>
                    </div>
                </div>
            </div>
            <img
                src={floating3}
                className={`gfx-1 d-md-block d-none`}
            />
        </section>
    )
}
import React, { useState } from "react"

import { useScrollAnim } from "src/components/hooks/hooks"
import { Accordion } from "components/anti/accordion/accordion"
import { Image } from "components/anti/image/image"

import imgbpjs from "assets/img/bpjs/katgeori-bpjs.jpg"


export const BPJSKategori = () => {
    const [trigger, anim] = useScrollAnim()

    const [expanded, setExpanded] = useState(kategori[0].slug);


    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : "undefined");
    };

    return (
        <section className="py-main sc-bpjs-kategori">
            <div className="bg-light bg-kategori d-md-block d-none"></div>
            <div className="container">
                <div className="row row-5">
                    <div className="col-lg-6">
                        <Image src={imgbpjs} alt="img" ratio="r-1-1" className="img-fluid kategori-img mb-sm-down-5 w-md-550px" />
                    </div>
                    <div className="col-lg-6">
                        <div className="pb-5 position-relative">
                            <h2 className="font-weight-normal mb-3">Kategori BPJS Kesehatan</h2>
                            <p>Terdapat 3 kategori kelas BPJS Kesehatan yaitu kelas I, II, dan III atau PBI (Penerima Bantuan Iuran). Perbedaan fasilitas ketiganya adalah dari segi fasilitas rawat inap.</p>
                            <p className="text-muted font-italic">*Informasi diakses dari website BPJS Kesehatan per tanggal 1 Agustus 2020</p>
                        </div>
                        {kategori.map((data, i) => {
                            return (
                                <Accordion
                                    expandIconPlus
                                    title={data.title}
                                    className={`accordion-home-update-category`}
                                    defaultExpanded={kategori[0].slug}
                                    expandedKey={data.slug}
                                    currentExpanded={expanded}
                                    onChange={handleChange(data.slug)}
                                    key={i}
                                >
                                    <div className="d-lg-block d-none">
                                        <div className="row row-4">
                                            <div className="col-4">
                                                <p className="caption">Harga</p>
                                                <h4>{data.harga}</h4>
                                            </div>
                                            <div className="col-4">
                                                <p className="caption">Fasilitas</p>
                                                <p>{data.fasilitas}</p>
                                            </div>
                                            <div className="col-4">
                                                <p className="caption">Kapasitas</p>
                                                <p>{data.kapasitas}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-block d-lg-none">
                                        <div className="row">
                                            <div className="col-6">
                                                <p className="caption">Harga</p>
                                            </div>
                                            <div className="col-6">
                                                <h4>{data.harga}</h4>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <p className="caption">Fasilitas</p>
                                            </div>
                                            <div className="col-6">
                                                <p>{data.fasilitas}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <p className="caption">Kapasitas</p>
                                            </div>
                                            <div className="col-6">
                                                <p>{data.kapasitas}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Accordion>
                            )
                        })}
                    </div>
                </div>
            </div>
        </section>
    )
}


export const kategori = [
    {
        slug: "kelasI",
        title: "Kelas I",
        harga: "Rp150.000",
        fasilitas: "Ruang Rawat Inap",
        kapasitas: "2-4 Orang",
    },
    {
        slug: "kelasII",
        title: "Kelas II",
        harga: "Rp100.000",
        fasilitas: "Ruang Rawat Inap",
        kapasitas: "3-5 Orang",
    },
    {
        slug: "kelasIII",
        title: "Kelas III",
        harga: "Rp35.000",
        fasilitas: "Ruang Rawat Inap",
        kapasitas: ">5 Orang",
    },
]